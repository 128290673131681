.swing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    position: relative;
    z-index: 100;
  }
  
  .hook {
    width: 10px;
    height: 30px;
    background-color: #000;
    border-radius: 5px 5px 0 0;
    position: absolute;
    top: 8px;
  }
  
  /* .hook::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 50%;
    width: 2px;
    height: 15px;
    background-color: #000;
    transform: translateX(-50%);
  } */
  
  .ribbon-left, .ribbon-right {
    width: 2px;
    height: 50px; /* Length of the ribbon segments */
    background-color: red; /* Color of the ribbon */
    position: absolute;
    top: 30px; /* Position it just below the hook */
    z-index: -1; /* Ensure the ribbon is behind the image */
  }
  
  .ribbon-left {
    left: 49.1%;
    transform: translateX(-50%) rotate(45deg); /* Rotate to create the left side of the V */
  }
  
  .ribbon-right {
    right: 48.9%;
    transform: translateX(-50%) rotate(-45deg); /* Rotate to create the right side of the V */
  }
  
  .swing-image {
    /* height: 400px; */
    margin-top: -30px;
    transform-origin: top center;
    animation: swing 5s ease-in-out infinite;
    /* box-shadow: 5px 10px 20px 10px #0000004b; */
  }
  
  @keyframes swing {
    0% { transform: rotate(5deg); }
    50% { transform: rotate(-5deg); }
    100% { transform: rotate(5deg); }
  }

  @media only screen and (max-width: 992px) {
    .swing-image {
      margin-top: 10px;
    }
    .ribbon-left, .ribbon-right {
      width: 2px;
      height: 50px; /* Length of the ribbon segments */
      background-color: red; /* Color of the ribbon */
      position: absolute;
      top: 30px; /* Position it just below the hook */
      z-index: -1; /* Ensure the ribbon is behind the image */
    }
    
    .ribbon-left {
      left: 44.5%;
      transform: translateX(-50%) rotate(45deg); /* Rotate to create the left side of the V */
    }
    
    .ribbon-right {
      right: 44%;
      transform: translateX(-50%) rotate(-45deg); /* Rotate to create the right side of the V */
    }
    .swing-container {
      height: 50vh;
    }
    
    
  }
  